.CoreScrollWrapper {
  * {
    word-wrap: normal; //IE10 fix for items in CoreScroll wrapping instead of being hidden when overflowing
  }

  overflow: hidden;
  white-space: nowrap;
  height: 100%;
}

.CoreScroll {
  display: flex;
}

.CoreScroll > * {
  box-sizing: border-box;
  vertical-align: top;
}

// New CoreScroll Styling

.CoreScrollWrapper--next {
  box-sizing: border-box;
  position: relative;

  * {
    box-sizing: border-box;
    word-wrap: normal; //IE10 fix for items in CoreScroll wrapping instead of being hidden when overflowing
  }

  .CoreScroll__top-section {
    & > * {
      width: 50%;
    }
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .CoreScroll__heading {
    font-size: 16px;
    font-weight: bold;
  }

  .CoreScroll__button-wrapper {
    text-align: right;
  }

  .CoreScroll__button {
    background-color: transparent;
    border: none;
    color: currentColor;
  }

  .CoreScroll {
    & > * {
      padding: 20px 10px;
      width: ~'calc(100% / 6)';
      min-width: 320px;
      &:first-child {
        padding-left: 0;
      }
    }

    &.quint > * {
      width: ~'calc(100% / 5)';

    }

    &.quad > * {
      width: ~'calc(100% / 4)';

    }

    &.triple > * {
      width: ~'calc(100% / 3)';

    }
    &.couple > * {
      width: ~'calc(100% / 2)';

    }

    &.single > * {
      width: 100%;

    }
  }
}
