
.district-dropdown {
  color: #fff;
  text-indent: 100%;
  white-space: nowrap;
  height: auto;
  position: absolute;
  top: 0;
  right: 0;

  // .district-dropdown-selector {
  //   margin: 0;
  //   position: relative;
  //   text-indent: -99em;
  //   width: 100%;
  //   height: 100%;
  //   .border_radius_right;
  // }

  .arrow-down {
    .arrow-down-mixin(6px, 7px, #fff);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -3.5px;
    margin-left: -6px;
    display: block;
  }

  // .text {
  //   display: none;
  // }

  // &.hover,
  // &:hover,
  // &:focus {
  //   .district-dropdown-selector {
  //     background-color: @linkHoverColor;
  //   }
  // }
}
