.discover-section {
  margin-bottom: 40px;
  position: relative;

  &:hover .discover-scroll-button-wrapper {
    opacity: 1;
  }

  @media (hover: none) {
    .discover-scroll-button-wrapper {
      display: none;
    }
  }

  // .discover-plug {
  //   display: inline-block;
  //   vertical-align: top;
  //   flex: 0 0 auto;
  //   margin-right: 24px;
  //   padding-right: 0 !important;
  //   position: relative;
  //   width: 275px;
  //   white-space: normal;

  //   &:active,
  //   &:focus,
  //   &:hover {
  //     text-decoration: none;

  //     * {
  //       text-decoration: none !important;
  //     }
  //   }
  // }
  .discover-scroll-button-wrapper {
    width: 275px;
  }
}

// .mobile-slider {
//   width: 100%;
//   overflow: hidden;
//   overflow-x: scroll;
//   white-space: nowrap;
//   -webkit-overflow-scrolling: touch;
// }

// .slider-previous,
// .slider-next {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border: none;
//   position: absolute;
//   width: 64px;
//   height: 64px;
//   font-size: 1rem;
//   background: #ffffff;
//   box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
//   border-radius: 6px;
//   color: black;
//   cursor: pointer;
//   z-index: 1;
//   transition: all 0.2s ease;
//   pointer-events: auto;

//   &:hover {
//     background: #eee;
//   }

//   &:disabled {
//     pointer-events: none;
//     opacity: 0;
//   }
// }

// .unselectable {
//   -webkit-user-select: none; /* Chrome all / Safari all */
//   -moz-user-select: none; /* Firefox all */
//   -ms-user-select: none; /* IE 10+ */
//   user-select: none; /* Likely future */
// }

@media all and (min-width: 430px) {
  .discover-section {
    @media (hover: none) {
      .discover-scroll-button-wrapper {
        display: none;
      }
    }

    // .discover-plug {
    //   width: 325px;
    // }

    .discover-scroll-button-wrapper  {
      width: 325px;
    }
  }
}
