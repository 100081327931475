.nrkMenu {
  position: absolute;
  z-index: 999;
  font-size: 1em;
  border-radius: 6px;
  overflow: auto;
  width: 100%;
  box-sizing: border-box;

  &.nrkMenu--state-open {
    display: block;
  }

  .nrkMenu__menuElement--divider {
    border-top: 1px solid @separatorColor;
    padding-top: 5px;
    margin-top: 5px;
  }

  a {
    padding: 0.25em 0.75em;
    border-left: 2px solid transparent;
    display: block;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;

    &:hover,
    &.nrkMenu__menuElement--state-focus {
      background-color: #131415;
      border-color: rgba(102, 209, 242, 0.4);
      color: #66d1f2;
      margin: 0;
      text-decoration: none;
    }

    &.nrkMenu__menuElement--state-selected {
      background-color: #1a1b1d;
      border-color: #66d1f2;
      color: #66d1f2;
      margin: 0;
    }
  }
}
