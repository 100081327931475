/* less mixins */

// @import "libs/elements.less";

// .border_radius(@radius: 3px) {
// 	-moz-border-radius: @radius;
// 	-webkit-border-radius: @radius;
// 	border-radius: @radius;
// }
// .border_radius_top(@radius: 3px) {
// 	-webkit-border-top-right-radius: @radius;
// 	-webkit-border-top-left-radius: @radius;
// 	-moz-border-radius-topright: @radius;
// 	-moz-border-radius-topleft: @radius;
// 	border-top-left-radius: @radius;
// 	border-top-right-radius: @radius;
// }
// .border_radius_bottom(@radius: 3px) {
// 	-webkit-border-bottom-right-radius: @radius;
// 	-webkit-border-bottom-left-radius: @radius;
// 	-moz-border-radius-bottomright: @radius;
// 	-moz-border-radius-bottomleft: @radius;
// 	border-bottom-left-radius: @radius;
// 	border-bottom-right-radius: @radius;
// }
// .border_radius_left(@radius: 3px) {
// 	-webkit-border-bottom-left-radius: @radius;
// 	-webkit-border-top-left-radius: @radius;
// 	-moz-border-radius-bottomleft: @radius;
// 	-moz-border-radius-topleft: @radius;
// 	border-top-left-radius: @radius;
// 	border-bottom-left-radius: @radius;
// }
// .border_radius_right(@radius: 3px) {
// 	-webkit-border-top-right-radius: @radius;
// 	-webkit-border-bottom-right-radius: @radius;
// 	-moz-border-radius-topright: @radius;
// 	-moz-border-radius-bottomright: @radius;
// 	border-top-right-radius: @radius;
// 	border-bottom-right-radius: @radius;
// }

// .transform(@property: none) {
// 	-moz-transform: @property;
// 	-webkit-transform: @property;
// 	-o-transform: @property;
// 	-ms-transform: @property;
// 	transform: @property;
// }

// .transition_short(@property: all, @duration: 0.1s, @timing: ease-in-out) {
// 	-webkit-transition: @property @duration @timing;
// 	-moz-transition: @property @duration @timing;
// 	-ms-transition: @property @duration @timing;
// 	-o-transition: @property @duration @timing;
// 	 transition: @property @duration @timing;
// }
// .transition_property(...){
// 	@properties: ~`(function(){ var arg = "@{arguments}" || "0"; return arg.replace("[","").replace("]",""); }())`;
// 	-webkit-transition-property: @properties;
// 	-moz-transition-property: @properties;
// 	-o-transition-property: @properties;
// 	-ms-transition-property: @properties;
// 	transition-property: @properties;
// }

// .box_sizing(@sizing: content-box) {
// 	-webkit-box-sizing: @sizing;
// 	-moz-box-sizing: @sizing;
// 	box-sizing: @sizing;
// }

// .appearance(@appearance: none) {
// 	-webkit-appearance: @appearance;
// 	-moz-appearance: @appearance;
// 	appearance: @appearance;
// }

// .box_shadow(@shadow: 0px 0px 3px 0px #000) {
// 	-webkit-box-shadow: @shadow;
// 	-moz-box-shadow: @shadow;
// 	box-shadow: @shadow;
// }

// .vertical_gradient(@bgColor, @firstColor, @secondColor, @firstStop: 0%, @secondStop: 100%) {
// 	background: @bgColor;
// 	background: -webkit-linear-gradient(top, @firstColor @firstStop, @secondColor @secondStop); // Safari 5.1 to 6.0
// 	background: -o-linear-gradient(top, @firstColor @firstStop, @secondColor @secondStop);     // Opera 11.1 to 12.0
// 	background: -moz-linear-gradient(top, @firstColor @firstStop, @secondColor @secondStop);   // Firefox 3.6 to 15
// 	background: linear-gradient(to bottom, @firstColor @firstStop, @secondColor @secondStop);
// }
/*.horizontal_gradient(@bgColor, @firstColor, @secondColor, @firstStop: 0%, @secondStop: 100%) {
	background: @bgColor;
	background: -webkit-linear-gradient(left, @firstColor @firstStop, @secondColor @secondStop); // Safari 5.1 to 6.0
	background: -o-linear-gradient(left, @firstColor @firstStop, @secondColor @secondStop);     // Opera 11.1 to 12.0
	background: -moz-linear-gradient(left, @firstColor @firstStop, @secondColor @secondStop);   // Firefox 3.6 to 15
	background: linear-gradient(to right, @firstColor @firstStop, @secondColor @secondStop);
}*/

// .overflow_text_helper(@text, @overflow) {
// 	-o-text-overflow: @text;
// 	-moz-text-overflow: @text;
// 	-webkit-text-overflow: @text;
// 	text-overflow: @text;
// 	overflow: @overflow;
// }
// .overflow_text(@text: ellipsis, @overflow: hidden, @space: nowrap) {
// 	.overflow_text_helper(@text, @overflow);
// 	white-space: @space;
// }

// .input-placeholder(@color) {
// 	&::-webkit-input-placeholder {
// 		color: @color;
// 	}
// 	&:-moz-placeholder {
// 		color: @color;
// 	}
// 	&::-moz-placeholder {
// 		color: @color;
// 	}
// 	&:-ms-input-placeholder {
// 		color: @color;
// 	}
// }

// /* Arrow Icons */
// .arrow-up-mixin(@height, @width, @color) {
// 	border-right: @height solid transparent;
// 	    border-bottom: @width solid @color;
// 	    border-left: @height solid transparent;
// 	width: 0;
// 	    height: 0;
// }
// .arrow-right-mixin(@height, @width, @color) {
// 	border-top: @width solid transparent;
// 	    border-bottom: @width solid transparent;
// 	    border-left: @height solid @color;
// 	width: 0;
// 	    height: 0;
// }
.arrow-down-mixin(@height, @width, @color) {
  border-top: @width solid @color;
  border-right: @height solid transparent;
  border-left: @height solid transparent;
  width: 0;
  height: 0;
}
// .arrow-left-mixin(@height, @width, @color) {
// 	border-top: @width solid transparent;
// 	    border-right: @height solid @color;
// 	    border-bottom: @width solid transparent;
// 	width: 0;
// 	    height: 0;
// }

// /* Fonts */
// .etica() {
// 	font-family: "LFT Etica", Arial, sans-serif;
// 	font-weight: normal;
// }

// .meta-small() {
// 	font-size: 12/12em;
// 	line-height: 14/12;
// 	font-style: italic;
// 	display: block;
// 	margin: 3px 0;
// }
