.district-menu {
  &.nrkMenu {
    border: none;
    background-color: #28292f;
    box-shadow: 2px 2px 8px #000000;

    button {
      font-size: 0.875rem;
      cursor: pointer;
      padding: 0.5em 0.75em;
      border: none;
      border-left: 2px solid transparent;
      display: block;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      text-align: left;
      width: 100%;
      background: transparent;
      color: white;
      &:hover,
      &:focus,
      &.nrkMenu__menuElement--state-focus {
        background-color: #131415;
        border-color: rgba(102, 209, 242, 0.4);
        color: #66d1f2;
        margin: 0;
        text-decoration: none;
      }

      &.nrkMenu__menuElement--state-selected {
        background-color: #1a1b1d;
        border-color: #66d1f2;
        color: #66d1f2;
        margin: 0;
      }
    }
  }
}

/* hide some channels on different breakpoints */
// .channel-grid.closed ul {
//   max-height: 125px;
// }
@media all and (min-width: 600px) {
  .channel {
    vertical-align: top;
    display: inline-block;
    zoom: 1;
    *display: inline;
  }
}
@media all and (min-width: 768px) {
  .channel-selector-bottom {
    display: none;
  }
}
.live-animation {
  display: inline-block;
  position: relative;
  margin-right: 6px;
  width: 20px;
  height: 24px;

  .bar {
    position: absolute;
    bottom: 0;
    width: 20%;
    border-radius: 20% 20% 0 0;
    height: 100%;
    background: rgba(255, 255, 255, 1);
    transform-origin: center bottom;

    &:nth-child(1) {
      left: 0px;
      -webkit-transform: scale(1, 0.2);
      transform: scale(1, 0.2);
      -webkit-animation: barUp1 2s infinite;
      animation: barUp1 2s infinite;
    }
    &:nth-child(2) {
      left: 40%;
      -webkit-transform: scale(1, 0.4);
      transform: scale(1, 0.4);
      -webkit-animation: barUp2 2s infinite;
      animation: barUp2 2s infinite;
    }
    &:nth-child(3) {
      left: 80%;
      -webkit-transform: scale(1, 0.6);
      transform: scale(1, 0.6);
      -webkit-animation: barUp3 2s infinite;
      animation: barUp3 2s infinite;
    }
  }
}

@keyframes barUp1 {
  0% {
    transform: scale(1, 0.2);
  }
  40% {
    transform: scale(1, 0.8);
  }
  50% {
    transform: scale(1, 0.4);
  }
  70% {
    transform: scale(1, 0.2);
  }
  90% {
    transform: scale(1, 0.9);
  }
  100% {
    transform: scale(1, 0.2);
  }
}
@keyframes barUp2 {
  0% {
    transform: scale(1, 0.4);
  }
  30% {
    transform: scale(1, 0.2);
  }
  40% {
    transform: scale(1, 0.6);
  }
  60% {
    transform: scale(1, 0.5);
  }
  80% {
    transform: scale(1, 0.8);
  }
  100% {
    transform: scale(1, 0.4);
  }
}
@keyframes barUp3 {
  0% {
    transform: scale(1, 0.6);
  }
  30% {
    transform: scale(1, 0.1);
  }
  40% {
    transform: scale(1, 1);
  }
  60% {
    transform: scale(1, 0.5);
  }
  80% {
    transform: scale(1, 0.2);
  }
  100% {
    transform: scale(1, 0.6);
  }
}
