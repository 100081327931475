dialog:not([open]) {
  display: none;
}

dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  color: #000;
  background-color: #fff;
}

backdrop,
#backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #121212;
  opacity: 0.8;
  z-index: 1400;
}
